import NavLinkGroup from '../../app/components/main-menu/components/nav-link-group/NavLinkGroup';
import NavLink from '../../app/components/main-menu/components/NavLink';
import UrlsService from '../../../common/router/services/urlsService.mdl';
import useUserManager from "../../../common/hooks/useUserManager";
import {useMemo} from "react";
import {UserRole} from "../../../common/enums/UserRole";

export default function ScheduleReportsLinks(): JSX.Element {
    const {isInRoles} = useUserManager();
    const isValidScope = useMemo(() => isInRoles([UserRole.ROOT_ADMIN, UserRole.SCHEDULE_REPORTS]), []);

    if (!isValidScope) {
        return null;
    }

    return (
        <NavLinkGroup label="Schedule Reports" to={UrlsService.scheduleReports()} iconName="icon-supply">
            <NavLink className="m-6" to={UrlsService.scheduleReports()}>Schedule Reports List</NavLink>
            <NavLink className="m-6" to={UrlsService.createScheduleReport()}>Create Schedule Report</NavLink>
        </NavLinkGroup>
    );
}
