export enum UserRole {
    USER = "USER",
    ADMIN = "ADMIN",
    PUBLISHER = "PUBLISHER",
    SUPER_ADMIN = "SUPER_ADMIN",
    ROOT_ADMIN = "ROOT_ADMIN",
    VIEW_ALL = "VIEW_ALL",
    READ_ONLY = "READ_ONLY",
    MILKSHAKE_ADMIN = "MILKSHAKE_ADMIN",
    VIEW_AS = "VIEW_AS",
    READ_ONLY_ADMIN = "READ_ONLY_ADMIN",
    VIEW_AS_STRICT = "VIEW_AS_STRICT",
    REPORTING_ONLY = "REPORTING_ONLY",
    INVITE_VIDAZOO = "INVITE_VIDAZOO",
    DEVELOPER = "DEVELOPER",
    SCHEDULE_REPORTS = "SCHEDULE_REPORTS"
}
