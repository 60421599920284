import * as React from 'react';
import UrlsService from '../../../common/router/services/urlsService.mdl';
import ScheduleReports from '../list/view/ScheduleReports';
import ScheduleReportEdit from '../item/edit/view/ScheduleReportEdit';
import ScheduleReportCreate from '../item/create/view/ScheduleReportCreate';
import AuthRoute from "../../../common/components/auth-route/AuthRoute";
import {UserRole} from "../../../common/enums/UserRole";
import {Route} from "react-router-dom";

const ScheduleReportsRouting = (
    <React.Fragment>
        <Route path={UrlsService.scheduleReports()}
               element={<AuthRoute scope={[UserRole.ROOT_ADMIN, UserRole.SCHEDULE_REPORTS]} element={<ScheduleReports/>} index={true}/>}/>
        <Route path={UrlsService.editScheduleReport()}
               element={<AuthRoute scope={[UserRole.ROOT_ADMIN, UserRole.SCHEDULE_REPORTS]} element={<ScheduleReportEdit/>} index={true}/>}/>
        <Route path={UrlsService.createScheduleReport()}
               element={<AuthRoute scope={[UserRole.ROOT_ADMIN, UserRole.SCHEDULE_REPORTS]} element={<ScheduleReportCreate/>} index={true}/>}/>
    </React.Fragment>
);

export default ScheduleReportsRouting
